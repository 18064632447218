import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Найдите элемент корневого контейнера в HTML
const container = document.getElementById('root');
// Создайте корневой рендерер с помощью createRoot
const root = createRoot(container);

// Используйте root.render вместо ReactDOM.render
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
