import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Section = styled.section`
  min-height: 100vh;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 48em) {
    width: 90vw;
  }

  @media (max-width: 30em) {
    width: 100vw;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontBig};
  font-family: "Kaushan Script";
  font-weight: 300;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`;

const Image = styled.img`
  width: 30%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 64em) {
    width: 45%;
  }

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const HomeLink = styled(Link)`
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  text-decoration: none;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: ${(props) => props.theme.body};

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const About = () => {
  return (
    <Section>
      <HomeLink to="/">Home</HomeLink>
      <Title>About Us</Title>
      <Text>
        We are a fine jewelry studio based in Cascais, Portugal. We create unique jewelry pieces with soul and attention using the most modern technologies and providing qualified service.
      </Text>
      <Text>
        At our atelier, we are dedicated to crafting jewelry that not only looks beautiful but also holds sentimental value. Each piece is made with utmost care, ensuring the highest quality and a touch of elegance.
      </Text>
      <ImagesContainer>
        <Image src="https://via.placeholder.com/400x600" alt="Jewelry 1" />
        <Image src="https://via.placeholder.com/400x600" alt="Jewelry 2" />
        <Image src="https://via.placeholder.com/400x600" alt="Jewelry 3" />
      </ImagesContainer>
    </Section>
  );
};

export default About;
