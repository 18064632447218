import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: transparent;
  box-shadow: none;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    transform: scale(0.98);
  }
`;

const ImageContainer = styled.div`
  height: 280px; // Высота для десктопов
  margin-top: 2rem; // Отступ сверху

  @media (max-width: 768px) {
    height: 140px; // Высота для мобильных устройств
    margin-top: 1rem; // Уменьшенный отступ сверху для мобильных устройств
  }

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 1rem;
  padding-top: 2rem; // Отступ сверху для текста
  text-align: center;
  font-family: 'Montserrat', sans-serif; // Измененный шрифт на Montserrat
  font-weight: bold; // Полужирный шрифт
  text-transform: uppercase; // Текст заглавными буквами
`;

const Title = styled.h2`
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

const ProductCard = ({ product }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/works/${product.name.toLowerCase().replace(/\s+/g, '-')}`);
  };

  return (
    <Card onClick={handleClick}>
      <ImageContainer>
        <Image src={`/assets/Images/${product.image}`} alt={product.name} />
      </ImageContainer>
      <Content>
        <Title>{product.name}</Title>
      </Content>
    </Card>
  );
};

export default ProductCard;
