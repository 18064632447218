import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3A3630;
`;

const RectContainer = styled(motion.div)`
  border: 1.5px solid #A7A7A7;
  width: calc(100vw - 150px);
  height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-family: 'Times New Roman', serif;
  padding: 10px;
  line-height: 1.5; /* Межстрочный интервал */

  @media (max-width: 768px) {
    border: 1.5px solid #A7A7A7;
    width: calc(100vw - 65px);
    height: calc(100vh - 65px);
    padding: 3px;
  }
`;

const containerVariants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.15,
      when: 'beforeChildren',
      staggerChildren: 0.15,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.15 },
  },
};

const rectVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
};

const Loader = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000); // 8 секунд до скрытия лоадера
    return () => clearTimeout(timer);
  }, []);

  if (!visible) return null;

  // Функция для вычисления размера текста относительно ширины экрана
  const getTextSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      // Для больших экранов (например, компьютеров)
      return screenWidth * 0.005; // Например, 3% от ширины экрана
    } else {
      // Для мобильных устройств
      return screenWidth * 0.03; // Например, 6% от ширины экрана
    }
  };

  return (
    <>
      <Container
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={containerVariants}
      >
        <RectContainer variants={rectVariants}>
          <div>
            <p style={{ fontSize: `${getTextSize() * 3}px`, marginBottom: '10px' }}>
              Iodis & Shilling
            </p>
            <p style={{ fontSize: `${getTextSize() * 1}px`, marginBottom: '10px' }}>
              JEWELRY
            </p>
            <p style={{ fontSize: `${getTextSize() * 2}px`, marginBottom: '10px' }}>
              ATELIER
            </p>
          </div>
        </RectContainer>
      </Container>
    </>
  );
};

export default Loader;
