import React from 'react';
import styled from 'styled-components';
import backgroundVideoGaruda from "../assets/Garuda_Cover_short.mp4";

const Section = styled.section`
  background-color: white;
  color: #333; /* Практически черный цвет текста */
  padding: 4rem;
  min-height: 120vh; /* Высота секции для двух скроллов */
`;

const Heading = styled.h2`
  font-size: 3rem;
  margin-bottom: 1rem;
  margin-top: 4rem; /* Добавляем больше пространства сверху */
  color: #333; /* Практически черный цвет заголовка */
`;

const SubText = styled.p`
  font-size: 1.5rem;
  color: #777; /* Серый цвет текста */
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: ${({ alignRight }) => (alignRight ? 'flex-end' : 'flex-start')};
  align-items: flex-start;
  margin-bottom: 10vh; /* Регулируем расстояние между блоками */

  &:nth-child(even) {
    justify-content: flex-start; /* Четные выравниваем слева */
  }

  &:nth-child(odd) {
    justify-content: flex-end; /* Нечетные выравниваем справа */
  }
`;

const TextBlock = styled.div`
  width: 45%;
  max-width: 600px;
  padding: 1rem;
`;

const VideoBlock = styled.div`
  width: 45%;
  max-width: 600px;
  padding: 1rem;

  video {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Можно добавить закругленные углы */
  }
`;

const TwoColumnSection = () => {
  return (
    <Section>
      <ContentWrapper>
        {/* Первый заголовок */}
        <Row alignRight={true}>
          <TextBlock>
            <Heading>Individual design</Heading>
            <SubText>
              We create jewelry that is uniquely tailored to your vision, ensuring each piece reflects your individuality.
            </SubText>
          </TextBlock>
        </Row>

        {/* Второй заголовок и видео */}
        <Row alignRight={false}>
          <TextBlock>
            <Heading>Personalisation</Heading>
            <SubText>
              Every detail is customizable, from the choice of materials to the intricate design elements, making your jewelry truly one of a kind.
            </SubText>
          </TextBlock>
          <VideoBlock>
            <video autoPlay loop muted playsInline>
              <source src="../assets/Garuda_Cover_short.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VideoBlock>
        </Row>

        {/* Третий заголовок и видео */}
        <Row alignRight={true}>
          <VideoBlock>
            <video autoPlay loop muted playsInline>
              <source src="../assets/Garuda_Cover_short.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VideoBlock>
          <TextBlock>
            <Heading>Own craft production</Heading>
            <SubText>
              Our in-house production allows us to maintain the highest standards of quality and craftsmanship in every piece we create.
            </SubText>
          </TextBlock>
        </Row>
      </ContentWrapper>
    </Section>
  );
};

export default TwoColumnSection;
