import { AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Loader from "./components/Loader";
import Home from "./sections/Home";
import Works from "./sections/Works";
import About from "./sections/About";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import Navbar from "./components/Navbar.jsx"; // Импортируем компонент Navbar



function App() {
  const [Loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  return (
    <ThemeProvider theme={dark}>
      <GlobalStyles />
    
        <AnimatePresence mode="wait">{Loaded ? null : <Loader />}</AnimatePresence>
        <main className="App">
          {/* Включаем Navbar здесь */}
          <Navbar />
          <AnimatePresence exitBeforeEnter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/works" element={<Works />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </AnimatePresence>
          
        </main>
        
    </ThemeProvider>
  );
}

export default App;
