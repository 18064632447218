import React, { Suspense } from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar'; // Убедитесь, что путь к Navbar правильный
import CoverVideo from '../components/CoverVideo'; // Пример с CoverVideo
import TwoColumnSection from "../sections/TwoColumnSection.jsx";

const AboutPreview = React.lazy(() => import('../components/AboutPreview'));
const GalleryPreview = React.lazy(() => import('../components/GalleryPreview'));
const ParallaxText = React.lazy(() => import('../components/ParallaxText'));
const ContactFormMain = React.lazy(() => import('../components/ContactFormMain'));
const VideoSection = React.lazy(() => import('../components/VideoSection'));



const Section = styled.section`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
`;

const Home = () => {
  return (
    <Section id="home">
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        <CoverVideo /> {/* Пример использования CoverVideo */}
        <AboutPreview />
        <GalleryPreview />
        <ParallaxText baseVelocity={-1}>WHEN ARTISTRY</ParallaxText>
        <VideoSection />
        <ParallaxText baseVelocity={1}>MEETS PRECISION</ParallaxText>
        <TwoColumnSection />
        <ContactFormMain />
      </Suspense>
    </Section>
  );
};

export default Home;
