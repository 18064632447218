import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion"; // Если используется анимация для стрелки

import MainVideo from "../assets/Walking Girl.mp4";


const VideoContainer = styled.section`
  width: 100%;
  height: 60vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    width: 100%;
    height: 60vh;
    object-fit: cover;

    @media (max-width: 48em) {
      object-position: center 10%;
    }
    @media (max-width: 30em) {
      object-position: center 50%;
    }
  }
`;



const ArrowContainer = styled(motion.div)`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
`;

const arrowVariants = {
  animate: {
    y: [0, -10, 0], // движение вверх и вниз
    transition: {
      duration: 2,
      repeat: Infinity, // бесконечная анимация
      ease: "easeInOut",
    },
  },
};

const CoverVideo = () => {
  return (
    <VideoContainer data-scroll>
     
      <video src={MainVideo} type="video/mp4" autoPlay muted loop />
      <ArrowContainer variants={arrowVariants} animate="animate">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="12" y1="5" x2="12" y2="19" />
          <polyline points="19 12 12 19 5 12" />
        </svg>
      </ArrowContainer>
    </VideoContainer>
  );
};

export default CoverVideo;
