import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';
import ProductCard from '../components/ProductCard';
import Navbar from '../components/Navbar'; // Импорт Navbar

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0rem;
  background-color: transparent;

  @media (max-width: 768px) {
    padding: 0rem;
    justify-content: space-between; // Для двух колонок на мобильных устройствах
  }
`;

const CardWrapper = styled.div`
  flex: 1 1 calc(33.333% - 1rem); // 3 колонки на десктопе
  margin: 0;
  position: relative;
  box-sizing: border-box;
  height: 526px; // Высота для десктопов
  {border-right: 0.1px solid #ccc; // Границы справа}
  {border-bottom: 0.1px solid #ccc; // Границы снизу}

  @media (max-width: 940px) 
  {
    flex: 1 1 calc(50% - 0.5rem); // 2 колонки для устройств с шириной от 768 до 940 пикселей
    height: 321px; // Высота для мобильных устройств

   {
      border-right: 0.1px solid #ccc; // Границы справа, кроме последней колонки
    }

   {
      border-bottom: 0.1px solid #ccc; // Границы снизу, кроме последнего ряда
    }
  }

  @media (max-width: 568px) {
    flex: 1 1 calc(50% - 0.5rem); // 2 колонки для устройств с шириной менее 568 пикселей
    height: 321px; // Высота для мобильных устройств

  {
      border-right: 0.1px solid #ccc; // Границы справа, кроме последней колонки
    }

    {
      border-bottom: 0.1px solid #ccc; // Границы снизу, кроме последнего ряда
    }
  }
`;

const Works = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('/assets/data/products.csv')
      .then(response => response.text())
      .then(data => {
        Papa.parse(data, {
          header: true,
          complete: (results) => {
            setProducts(results.data);
          },
        });
      });
  }, []);

  return (
    <div>
      <Navbar /> {/* Подключение Navbar */}
      <Section>
        {products.map((product, index) => (
          <CardWrapper key={index}>
            <ProductCard product={product} />
          </CardWrapper>
        ))}
      </Section>
    </div>
  );
};

export default Works;
