import { motion } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Импорт PNG изображений
import MenuLogoWhite from '../assets/Svgs/menu_logo_white.png'; // Путь к вашему белому логотипу
import MenuLogoBlack from '../assets/Svgs/menu_logo_black.png'; // Путь к вашему черному логотипу

// Контейнер для навигации
const NavContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${(props) => (props.isTransparent ? 'transparent' : 'transparent')};
  backdrop-filter: ${(props) => (props.isTransparent ? 'none' : 'blur(20px)')};
  -webkit-backdrop-filter: ${(props) => (props.isTransparent ? 'none' : 'blur(20px)')};
  background-image: ${(props) => (props.isTransparent ? 'none' : 'linear-gradient(120deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.2))')};
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease, background-image 0.3s ease;
`;

// Кнопка для меню
const MenuButton = styled(motion.button)`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 6rem;
    height: auto;
  }
  @media (max-width: 768px) {
    img {
      width: 4rem;
      height: auto;
    }
  }
`;

// Оверлей для меню
const MenuOverlay = styled(motion.div)`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f0e5de;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
`;

// Список пунктов меню
const MenuItems = styled(motion.ul)`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

// Пункт меню
const MenuItem = styled(motion.li)`
  text-transform: uppercase;
  color: #8b0000;
  font-size: 2rem;
  cursor: pointer;
  a {
    text-decoration: none;
    color: inherit;
  }
`;

// Кнопка закрытия меню
const CloseButton = styled(motion.button)`
  position: absolute;
  bottom: 2rem;
  background: none;
  border: none;
  font-size: 2rem;
  color: #8b0000;
  cursor: pointer;
`;

const Navbar = () => {
  const [open, setOpen] = useState(false); // Состояние открытия меню
  const [isScrolled, setIsScrolled] = useState(false); // Состояние скроллинга
  const [lastScrollY, setLastScrollY] = useState(0); // Последнее положение скроллинга
  const [logoColor, setLogoColor] = useState('white'); // Цвет логотипа
  const [isTransparent, setIsTransparent] = useState(true); // Прозрачность навбара

  const toggleMenu = () => {
    setOpen(!open); // Переключение состояния меню
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      // Если скроллим вниз
      setIsScrolled(true);
      setTimeout(() => setIsTransparent(false), 300); // Сначала анимация сдвига, потом прозрачность
      
    } else {
      // Если скроллим вверх
      setIsScrolled(false);
      setIsTransparent(false); // Устанавливаем стекло
      setLogoColor('black');
     
        if (currentScrollY === 0) {
          setTimeout(() => {
          setLogoColor('white');
        }, 50);
        setTimeout(() => {
          setIsTransparent(true); // Прозрачность после анимации сдвига
        }, 70);
        } else {
          
        }
     
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <NavContainer
        isTransparent={isTransparent}
        animate={{ y: isScrolled ? '-100%' : '0%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <MenuButton onClick={toggleMenu}>
          <img
            src={logoColor === 'white' ? MenuLogoWhite : MenuLogoBlack}
            alt="Menu"
          />
        </MenuButton>
      </NavContainer>
      <MenuOverlay open={open}>
        <MenuItems>
          <MenuItem onClick={toggleMenu}>
            <Link to="/works">Works</Link>
          </MenuItem>
          <MenuItem onClick={toggleMenu}>
            <Link to="/about">About</Link>
          </MenuItem>
        </MenuItems>
        <CloseButton onClick={toggleMenu}>×</CloseButton>
      </MenuOverlay>
    </>
  );
};

export default Navbar;
